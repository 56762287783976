import { HTTPMethod, Resource } from 'model/enum';
import { User } from 'model/model';
import { LoginResponse } from 'model/response';
import { apiInfo, fetchRequest } from './api';

export const signIn = (username: string, password: string): Promise<LoginResponse> => {
    const body = {
        username: username,
        password: password
    };

    return fetchRequest(
        HTTPMethod.POST,
        `${apiInfo.base}/${Resource.AUTH}/login`,
        body
    );
}

export const refreshSession = (): Promise<User> => {
    return fetchRequest(
        HTTPMethod.GET,
        `${apiInfo.base}/${Resource.AUTH}/user`,
    )
}