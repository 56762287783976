import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { LiveEventInfo } from 'model/model';
import backgroundImg from 'assets/images/splash-background.jpg';
import moment from 'moment';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import infoIcon from "../../../../assets/Info.svg"
import cardsIcon from "../../../../assets/icons/Cards.svg"
import goalsIcon from "../../../../assets/Goals.svg"
import substitutionsIcon from "../../../../assets/Substitutions.svg"
import varIcon from "../../../../assets/Var.svg"
import timeIcon from "../../../../assets/Time.svg"
import { HighlightOff } from '@mui/icons-material';

function Highlight(props: any) {
    const axios = require("axios")
    const highlight = props.highlight
    console.log(highlight)
    
    
    

    return (
        <>
            {highlight.playerOut.name ?
                <div className="highlight-card">
                    <div className="header">
                        <p style={{ "color": highlight?.color }}>{(highlight?.description.split(" "))[0]}</p>
                    </div>
                    <div className="content">
                        <img className='team' src={highlight?.teamLogo} alt="" />
                        <div className="text">
                            <p style={{"color":"green"}}>{highlight?.player?.name}</p>
                            <p style={{"color":"red"}}>{highlight?.playerOut?.name}</p>
                        </div>
                    </div>
                </div>
                :
                <div className="highlight-card">
                    <div className="header">
                        <p style={{ "color": highlight?.color }}>{highlight?.description}</p>
                    </div>
                    <div className="content">
                        <img className='team' src={highlight?.teamLogo} alt="" />
                        <div className="text">
                            <p>{highlight?.teamName}</p>
                            <p>{highlight?.player?.name}</p>
                        </div>
                        <img className="player" src={highlight?.player?.photo} alt="" />
                    </div>
                </div>}</>
    );
}

export default Highlight;