import moment from 'moment';

const STREAM_PREFIX = 'rtmp';

export const getOrigin = (component: React.Component): string => {
    if (
        (component.props as any).location !== undefined &&
        (component.props as any).location.state !== undefined &&
        (component.props as any).location.state.origin !== undefined
    )
        return (component.props as any).location.state.origin;

    return '/';
};

export const formatSecondsToTime = (seconds = 0): string => {
    var mom = moment().startOf('day').seconds(seconds);

    return mom.hours() ? mom.format('HH:mm:ss') : mom.format('mm:ss');
};

// * Crop a value between a minimum and a maximum limits
export const cropMinMax = (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), max);
};

// * Toggle full screen on element
export const toggleFullScreen = (element: HTMLElement) => {
    if (document.fullscreenElement) {
        document.exitFullscreen()
    } else {
        element.requestFullscreen();
    }
};

// * Check if stream is RTMP
export const isStreamRTMP = (streamKey: string) => {
    const res = streamKey?.substring(0, 4);
    if (res === STREAM_PREFIX) { return true; }
    else return false;
}

// * Get stream username
export const getUsername = (streamKey: string, streamUsername: string, streamIdentifier: number) => {
    let username = '';
    if (isStreamRTMP(streamKey)) {
        username = 'Camera ' + streamIdentifier;
    } else if (streamUsername) {
        username = streamUsername;
    } else {
        username = 'User ' + streamIdentifier;
    }

    return username;
}

export const to = <T>(promise: Promise<T>): Promise<(T | undefined)[] | [any, T | undefined]> => {
    return promise.then((data) => [undefined, data])
        .catch(err => [err, undefined]);
};