import React from 'react';
import { Socket } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { LiveStream } from 'model/model';
import { Typography } from '@mui/material';

import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';
import { RootState } from 'redux/reducer';
import { getUsername } from 'utils/utils';
import { ReactComponent as EmptyStar } from 'assets/icons/empty-star.svg';
import { ReactComponent as FullStar } from 'assets/icons/full-star.svg';

interface IStreamThumbnail {
    stream: LiveStream;
    selectStream: (stream: LiveStream) => void;
    dispatchLiveStreams: (action: any) => void;
    socket: Socket<ServerToClientEvents, ClientToServerEvents>
}

function StreamThumb(props: IStreamThumbnail) {
    const { stream, selectStream, dispatchLiveStreams, socket } = props;

    const user = useSelector((state: RootState) => state.user);

    if (!stream.imageData) { return <></>; }

    const removeFromFavorites = () => {
        dispatchLiveStreams({
            id: 'updateStreamInfo',
            hashStream: stream.hash,
            favorite: false
        });

        const removeFavorites = {
            type: 'delete',
            hashStream: stream.hash,
            userId: user.id
        };

        socket.emit('favorites', removeFavorites);
    }

    const addToFavorites = () => {
        dispatchLiveStreams({
            id: 'updateStreamInfo',
            hashStream: stream.hash,
            favorite: true
        });

        const addFavorites = {
            type: 'create',
            hashStream: stream.hash,
            userId: user.id
        };

        socket.emit('favorites', addFavorites);
    }

    const FavoriteIcon = () => (stream.favorite ? 
        <FullStar className="stream-star" onClick={() => removeFromFavorites()} /> : 
        <EmptyStar className="stream-star" onClick={() => addToFavorites()} />);

    return (
        <div className="stream-thumbnail">
            <FavoriteIcon />
            <div 
                className="stream-backdrop" 
                onClick={() => selectStream(stream)}
            >
                <Typography className="stream-username">
                    By {getUsername(stream.hash, stream.username, stream.identifier)}
                </Typography>
            </div>

            <img 
                src={stream.imageData} 
                alt="Live stream thumbnail" 
                onClick={() => selectStream(stream)}
            />
        </div>
    );
}

export default StreamThumb;