import React from 'react';
import { AppBar, Link, Toolbar, Typography } from '@mui/material';
import { NavLink, RouteProps, match } from 'react-router-dom';

import Dropdown from 'templates/dropdown/Dropdown';

import PlayOffLogo from 'assets/logo/playoff-logo.png';

function NavBar(props: {}) {
    const isActive = (match: match | null, location: RouteProps["location"], link: string) => {
        if(!location) return false;
        const { pathname } = location;
        return pathname === link;
    }

    return (
        <AppBar position="sticky" color="transparent" className="navbar">
            <Toolbar className="toolbar">

                <NavLink className="navbar-logo" to="/">
                    <img
                        src={PlayOffLogo}
                        alt="PlayOff Logo"
                    />
                </NavLink>

                <Typography
                    className="navbar-link"
                    color="secondary"
                    component="div"
                >
                    <Link
                        variant="inherit"
                        color="inherit"
                        underline="none"
                        component={NavLink}
                        activeClassName="selected-link"
                        isActive={(match: match | null, location: RouteProps["location"]) => isActive(match, location, "/")}
                        to="/"
                    >
                        Homepage
                    </Link>

                    <Link
                        href="#"
                        variant="inherit"
                        color="inherit"
                        underline="none"
                        component={NavLink}
                        activeClassName="selected-link"
                        isActive={(match: match | null, location: RouteProps["location"]) => isActive(match, location, "/live-game")}
                        to="/live-game"
                    >
                        Live Game
                    </Link>

                    {/*<Link
                        href="#"
                        variant="inherit"
                        color="inherit"
                        underline="none"
                    >
                        About PlayOff
                    </Link>*/}
                </Typography>

                <div className="navbar-fill" />

                <Dropdown />
            </Toolbar>
        </AppBar>
    );
}

export default NavBar;