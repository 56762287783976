const defaultColors = [
    '#0000FF',
    '#FF0000',
    '#8A2BE2',
    '#FF69B4',
    '#1E90FF',
    '#008000',
    '#00FF7F',
    '#B22222',
    '#DAA520',
    '#FF4500',
    '#2E8B57',
    '#5F9EA0',
    '#D2691E'
];

// https://en.wikipedia.org/wiki/Linear_congruential_generator
function _stringAsciiPRNG(value: string, m: number) {
    // Xn+1 = (a * Xn + c) % m
    // 0 < a < m
    // 0 <= c < m
    // 0 <= X0 < m

    const charCodes = [...value].map(letter => letter.charCodeAt(0));
    const len = charCodes.length;

    const a = (len % (m - 1)) + 1;
    const c = charCodes.reduce((current, next) => current + next) % m;

    let random = charCodes[0] % m;
    for (let i = 0; i < len; i++)
        random = ((a * random) + c) % m;

    return random;
}

export function getRandomColor(value: string, colors = defaultColors)
{
    // if no value is passed, always return transparent color otherwise
    // a rerender would show a new color which would will
    // give strange effects when an interface is loading
    // and gets rerendered a few consequent times
    if(!value)
        return 'transparent';

    // value based random color index
    // the reason we don't just use a random number is to make sure that
    // a certain value will always get the same color assigned given
    // a fixed set of colors
    const colorIndex = _stringAsciiPRNG(value, colors.length);
    return colors[colorIndex];
}
