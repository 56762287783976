import React from 'react';
import { Typography } from '@mui/material';
import { getRandomColor } from 'utils/colors';
import { parseContent } from 'components/livechat/emojiparser/EmojiParser';

interface IChatMessage {
    username: string;
    content: string;
    ownmessage: boolean;
}

function ChatMessage(props: IChatMessage) {
    return (
        <div className="chat-message">
            <Typography className="chat-username" component="span" style={{ color: getRandomColor(props.username) }}>
                {props.username}
            </Typography>

            <Typography className="chat-content" component="span">
                {parseContent(props.content)}
            </Typography>

        </div>
    );
}

export default ChatMessage;