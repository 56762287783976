import { combineReducers } from 'redux';
import { ACTION_TYPES, Action } from './actions';

const userReducer = (state = null, action: Action) => {
  switch (action.type) {
    case ACTION_TYPES.USER.UPDATE_USER:
      return { ...action.payload };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  user: userReducer
});

export type RootState = ReturnType<typeof reducer>
