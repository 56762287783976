export enum HTTPMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
    PATCH = 'patch',
};

export enum Resource {
    AUTH = 'auth',
    EVENTS = 'events',
    MESSAGES = 'messages',
    STREAMS = 'streams',
    USERS = 'users',
    ADMIN = 'admin',
};

export enum SidebarOptions {
    VIDEOS = 'videos',
    FAVORITES = 'favorites',
    CHAT = 'chat'
};

export enum FootballOptions {
    FOOTBALL = 'football',
    TEAMS = 'teams',
    STANDINGS = 'standings',
    FIXTURES = 'fixtures',
    SEASON = 'season',
    LINEUPS = 'lineups',
    STATISTICS = 'statistics',
    ROUNDS = 'rounds'
}