import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { Socket } from 'socket.io-client';
import Editor from '@draft-js-plugins/editor';

import EmojiPlugin from 'components/livechat/emojiplugin/EmojiPlugin';
import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';
import { RootState } from 'redux/reducer';

import { ReactComponent as EmojiIcon } from 'assets/icons/smile-icon.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send-icon.svg';

import 'emoji-mart/css/emoji-mart.css';
import 'draft-js/dist/Draft.css';

const { Picker } = EmojiPlugin;

interface IChatInput {
    socket: Socket<ServerToClientEvents, ClientToServerEvents>
}

function ChatInput(props: IChatInput) {
    const { socket } = props;
    const user = useSelector((state: RootState) => state.user);
    const theme = useTheme();

    const [newMessage, setNewMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    useEffect(() => {
        function handleClickOutside(event: any) {
            const el = event.target;

            const containerArray = document.getElementsByClassName('emoji-mart');
            const btnArray = document.getElementsByClassName('emoji-picker-btn');
            const emojiContainer = containerArray.length > 0 ? containerArray[0] : null;
            const emojiBtn = btnArray.length > 0 ? btnArray[0] : null;
            
            if (emojiContainer && !emojiContainer.contains(el)
                && emojiBtn && !emojiBtn.contains(el)) {
                setOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setNewMessage(editorState.getCurrentContent().getPlainText('\u000A'));
    }, [editorState]);

    const sendMessage = () => {
        if (!newMessage) { return; }
        const message = {
            userId: user.id,
            username: user.username,
            content: newMessage
        };

        socket.emit('sendMessage', message);
        
        resetEditor();
        setOpen(false);
    }

    const resetEditor = () => {
        let contentState = editorState.getCurrentContent();
        const firstBlock = contentState.getFirstBlock();
        const lastBlock = contentState.getLastBlock();
        const allSelected = new SelectionState({
            anchorKey: firstBlock.getKey(),
            anchorOffset: 0,
            focusKey: lastBlock.getKey(),
            focusOffset: lastBlock.getLength(),
            hasFocus: true
        });
        contentState = Modifier.removeRange(contentState, allSelected, 'backward');
        const newState = EditorState.push(editorState, contentState, 'remove-range');
        setEditorState(newState);
    }

    const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };
    
    return (
        <div className="chat-input-container">
            <div className="chat-input" onKeyDown={handleEnterPress}>

                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    plugins={[EmojiPlugin]}
                    placeholder="Write your own message..."
                />

                {open ? <Picker
                    set="apple"
                    title="Pick your emoji..."
                    emoji="point_up"
                    color={theme.palette.primary.main}
                    exclude={['symbols', 'objects', 'places', 'nature']}
                    perLine={7}
                    showSkinTones={false}
                    enableFrequentEmojiSort={true}
                /> : <></>}
            </div>

            <div
                className={`emoji-picker-btn ${open ? 'open' : 'close'}`}
                onClick={() => setOpen(!open)}
            >
                <EmojiIcon />
            </div>
            
            <SendIcon
                className="send-icon"
                onClick={(e) => sendMessage()}
            />
        </div>
    );
}

export default ChatInput;