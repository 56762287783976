import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Socket } from 'socket.io-client';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';
import BaseRouter from 'templates/baserouter/BaseRouter';
import AuthRouter from 'templates/authrouter/AuthRouter';
import Verification from 'pages/verification/Verification';
import { refreshSession } from 'api/auth';
import { UserActions } from 'redux/actions';
import { to } from 'utils/utils';
import { isLoggedIn } from 'utils/auth';

interface IAppRouter {
    socket: Socket<ServerToClientEvents, ClientToServerEvents>
};

function AppRouter(props: IAppRouter) {
    const socket = props.socket;
    const dispatch = useDispatch();
    const hasFetchedData = useRef(false);

    const getUserInformation = useCallback(async () => {
        const [err, result] = await to(refreshSession());
        if (err || !result) {
            console.error(' -- Error retrieving info for user');
            dispatch(UserActions.updateUser(null));

            if (isLoggedIn()) {
                window.location.href = '/auth/login';
                localStorage.removeItem('accessToken');
            }
        } else {
            dispatch(UserActions.updateUser(result))
        }

        hasFetchedData.current = true;
    }, [dispatch])

    useEffect(() => {
        if (hasFetchedData.current) { return; }

        getUserInformation();
    }, [getUserInformation]);

    return (
        <Router>
            <Switch>
                <Route path="/verification/:token" component={Verification} />

                <Route path="/auth" >
                    <AuthRouter />
                </Route>

                <Route path="/" >
                    <BaseRouter socket={socket} />
                </Route>
            </Switch>
        </Router>
    );
}

export default AppRouter;
