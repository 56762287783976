import React from 'react';
import { Socket } from 'socket.io-client';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';
import Home from 'pages/home/Home';
import LiveGame from 'pages/livegame/LiveGame';
import NavBar from 'templates/navbar/NavBar';
import Footer from 'templates/footer/Footer';
import { isLoggedIn } from 'utils/auth';

interface IBaseRouter {
    socket: Socket<ServerToClientEvents, ClientToServerEvents>;
};

function BaseRouter(props: IBaseRouter) {
    const socket = props.socket;

    if (!isLoggedIn()) {
        return <Redirect to={{ pathname: "/auth/login" }} />;
    }

    return (
        <div>
            <NavBar />
            <div className="body-content">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/live-game" render={(props) => <LiveGame socket={socket} {...props} />} />
                    <Redirect to="/" />
                </Switch>
            </div>

            <Footer />
        </div>
    );
}

export default BaseRouter;
