import React, { useEffect, useState, useCallback, ReactElement } from "react";
import { getStandings } from "api/standings";
import { getStatistics } from "api/statistics";
import { getFixtures } from "api/fixtures";
import { Event } from 'model/model';
import { getFixture } from "api/fixture";
import { getLineUp } from "api/lineup";
import { to } from "utils/utils";
import { getNextEvents } from 'api/event';
import { ReactComponent as WinIcon } from "assets/icons/win.svg";
import { ReactComponent as LostIcon } from "assets/icons/lost.svg";
import { ReactComponent as DrawIcon } from "assets/icons/draw.svg";
import Highlight from "./highlight/Highlight";
import { apiInfo } from "api/api";
const axios = require("axios")


function Statistics() {
  const statisticsSelectorClass: string = "Statistics";
  const standingsSelectorClass: string = "Standings";
  const squadsSelectorClass: string = "Squads";
  const groupStageSelectorClass: string = "group-stage";
  const last16SelectorClass: string = "last-16";
  const last8SelectorClass: string = "last-8";
  const last4SelectorClass: string = "last-4";
  const last2SelectorClass: string = "last-2";
  const finalSelectorClass: string = "final";
  let [selectedStanding, setSelectedStanding] = useState("group-stage");

  let [isStatisticsActive, setIsStatisticsActive] = useState(false);
  let [statisticsToggle, setStatisticsToggle] = useState(false)
  let [selectedStatistic, setSelectedStatistic] = useState("statistics");
  let [standings, setStandings] = useState<any>(null);
  let [fixture, setFixture] = useState<any>(null);
  let [fixtureEvents, setFixtureEvents] = useState<any>(null);
  let [lineUp, setLineUp] = useState<any>(null);
  let [fixtureId, setFixtureId] = useState<any>(null);
  let [statistics, setStatistics] = useState<any>(null);
  let [formattedStatistics, setFormattedStatistics] = useState<any>(null)
  let [currentHighlight, setCurretnHighlight] = useState<any>(null);
  let [isHighlightActive, setIsHighLightActive] = useState<any>(false)
  const [currentEvent, setCurrentEvent] = useState<any>(null);
  const [nextEvents, setNextEvents] = useState([] as Event[]);


  const getEventsInformation = async () => {
    let result;
    try {
      result = await getNextEvents();
    } catch (err) {
      console.error('  -- Error retrieving ongoing event information');
      return;
    }
    if (result.ongoingEvent) {
      setStatisticsToggle(true)
    }
    else {
      setStatisticsToggle(false)
    }
    setCurrentEvent(result.eventInfo);
    setNextEvents(result.nextEvents);
  }

  function removeActiveClass(class_name: string) {
    document.querySelector("." + class_name)?.classList.remove("active");
  }

  function addActiveClass(class_name: string) {
    document.querySelector("." + class_name)?.classList.add("active");
  }

  function statisticsHandler() {
    if (isStatisticsActive) {
      setSelectedStatistic("statistics");
    }
    setIsStatisticsActive(!isStatisticsActive);
  }

  function statisticsTab() {
    setSelectedStatistic("statistics");
    addActiveClass(statisticsSelectorClass);
    removeActiveClass(standingsSelectorClass);
    removeActiveClass(squadsSelectorClass);
  }

  function standingsTab() {
    setSelectedStatistic("standings");
    addActiveClass(standingsSelectorClass);
    removeActiveClass(squadsSelectorClass);
    removeActiveClass(statisticsSelectorClass);
  }

  function groupStageTab() {
    setSelectedStanding("group-stage")
    removeActiveClass(last16SelectorClass);
    removeActiveClass(last8SelectorClass);
    removeActiveClass(last4SelectorClass);
    removeActiveClass(last2SelectorClass);
    removeActiveClass(finalSelectorClass);
    addActiveClass(groupStageSelectorClass);
  }
  function last16Tab() {
    setSelectedStanding("last-16")
    removeActiveClass(groupStageSelectorClass);
    removeActiveClass(last8SelectorClass);
    removeActiveClass(last4SelectorClass);
    removeActiveClass(last2SelectorClass);
    removeActiveClass(finalSelectorClass);
    addActiveClass(last16SelectorClass);
  }
  function last8Tab() {
    setSelectedStanding("last-8")
    removeActiveClass(last16SelectorClass);
    removeActiveClass(groupStageSelectorClass);
    removeActiveClass(last4SelectorClass);
    removeActiveClass(last2SelectorClass);
    removeActiveClass(finalSelectorClass);
    addActiveClass(last8SelectorClass);
  }
  function last4Tab() {
    setSelectedStanding("last-4")
    removeActiveClass(last16SelectorClass);
    removeActiveClass(last8SelectorClass);
    removeActiveClass(groupStageSelectorClass);
    removeActiveClass(last2SelectorClass);
    removeActiveClass(finalSelectorClass);
    addActiveClass(last4SelectorClass);
  }
  function last2Tab() {
    setSelectedStanding("last-2")
    removeActiveClass(last16SelectorClass);
    removeActiveClass(last8SelectorClass);
    removeActiveClass(last4SelectorClass);
    removeActiveClass(groupStageSelectorClass);
    removeActiveClass(finalSelectorClass);
    addActiveClass(last2SelectorClass);
  }
  function finalTab() {
    setSelectedStanding("final")
    removeActiveClass(last16SelectorClass);
    removeActiveClass(last8SelectorClass);
    removeActiveClass(last4SelectorClass);
    removeActiveClass(last2SelectorClass);
    removeActiveClass(groupStageSelectorClass);
    addActiveClass(finalSelectorClass);
  }

  function statisticsPercentage(homeTeamValue: number, awayTeamvalue: number) {
    const total = homeTeamValue + awayTeamvalue;
    const homeResult = Math.round((homeTeamValue * 100) / total);
    const awayResult = Math.round((awayTeamvalue * 100) / total);

    const result = {
      homeResult,
      awayResult,
    };

    return result;
  }

  //This function is needed because of the returned API object. To be able to render the statistics in the DOM we need a different statistic's object structure.
  function statisticsArrayHandler() {
    if (statistics[0] != null && statistics[1] != null) {
      let result = []
      let home = statistics[0].statistics
      let away = statistics[1].statistics
      for (let i = 0; i < home.length; i++) {
        for (let j = 0; j < away.length; j++) {
          if (home[i].type == away[j].type) {
            let verifiedHomeValue, verifiedAwayValue
            home[i].value == null ? verifiedHomeValue = 0 : verifiedHomeValue = home[i].value
            away[j].value == null ? verifiedAwayValue = 0 : verifiedAwayValue = away[j].value
            let formattedObj = {
              statistic: {
                team: {
                  home: {
                    type: home[i].type,
                    value: parseInt(verifiedHomeValue)
                  },
                  away: {
                    type: away[j].type,
                    value: parseInt(verifiedAwayValue)
                  }
                }
              }
            }
            result.push(formattedObj)
          }
        }
      }
      setFormattedStatistics(result)
    }
    let formattedObj: object[] = []
    return formattedObj
  }

  function squadsTab() {
    setSelectedStatistic("squads");
    addActiveClass(squadsSelectorClass);
    removeActiveClass(standingsSelectorClass);
    removeActiveClass(statisticsSelectorClass);
  }

  function lastFiveGamesHandler(games: string, idx: number) {
    const winIcon: ReactElement = <WinIcon />;
    const lostIcon: ReactElement = <LostIcon />;
    const drawIcon: ReactElement = <DrawIcon />;
    const lastFiveGamesResult = games.split("");
    let iconsArray: ReactElement[] = [];
    for (let i = 0; i < lastFiveGamesResult.length; i++) {
      if (lastFiveGamesResult[i] === "W") {
        iconsArray.push(winIcon);
      }
      if (lastFiveGamesResult[i] === "D") {
        iconsArray.push(drawIcon);
      }
      if (lastFiveGamesResult[i] === "L") {
        iconsArray.push(lostIcon);
      }
    }
    return iconsArray;
  }

  const getStandingInformation = useCallback(async (leagueID, season) => {
    const [err, result] = await to(getStandings(leagueID, season));
    console.log("League ID: ", leagueID)

    if (err || !result) {
      console.log("ERRO");
      console.error(" -- Error retrieving ongoing standings information:", err);
      return;
    }

    if (result) {
      const standingsReturned: any = result;
      console.log("Standings: ", standingsReturned);

      setStandings(standingsReturned);
    }
    // if (result.nextEvents.length > 0) { nextEv = result.nextEvents[0]; }
  }, []);

  const getFixtureById = useCallback(async (id) => {
    const [err, result] = await to(getFixture(id));
    if (err || !result) {
      console.log("ERRO");
      console.error(" -- Error retrieving ongoing fixture information:", err);
      return;
    }
    if (result && (result as any)?.length > 0) {
      const fixtureReturned: any = result;
      setFixture(fixtureReturned);
      setFixtureEvents(fixtureReturned[0].events)
    }
  }, [])

  const getStatisticsInformation = useCallback(async (awayTeamID, fixtureID, homeTeamID) => {
    const [err, result] = await to(getStatistics(fixtureID, homeTeamID));
    const [err2, result2] = await to(getStatistics(fixtureID, awayTeamID));
    if (err || !result || err2 || !result2) {
      console.log("ERRO");
      console.error(" -- Error retrieving ongoing statistics information:", err);
      return;
    }
    if (result && result2) {
      const homeStatisticsReturned: any = result;
      const awayStatisticsReturned: any = result2;
      const statisticsArray = [homeStatisticsReturned[0], awayStatisticsReturned[0]]
      setStatistics(statisticsArray)
    }
  }, []);

  const getLineUpInformation = useCallback(async (id) => {
    const [err, result] = await to(getLineUp(id));
    if (err || !result) {
      console.log("ERRO");
      console.error(" -- Error retrieving ongoing line up information:", err);
      return;
    }
    if (result) {
      const lineUpReturned: any = result;
      setLineUp(lineUpReturned);
    }
  }, []);

  function getEventId() {
    console.log(currentEvent)
    if (currentEvent != null) {
      let id = currentEvent.fixtureid
      getFixtureById(id)
      setFixtureId(id)
      console.log("Fixture ID: ", id)
    }
  }

  async function getUpdatedFixture(fixtureEventsObj: any) {
    const { data } = await axios.get(
      `${apiInfo.base}/admin/football/fixtures/${fixtureId}`
    );
    setFixture(data)
    getStatisticsInformation(fixture[0].teams.away.id, fixtureId, fixture[0].teams.home.id)
    console.log("DATA-> ", data[0].events)
    console.log("OLD DATA->", fixtureEventsObj)
    const lastEvent = data[0].events[(data[0].events.length) - 1]

    if (data[0].events.length != fixtureEventsObj.length) {
      if (data[0].events.length > fixtureEventsObj.length) {
        let highlight = {
          teamLogo: lastEvent.team.logo,
          teamName: lastEvent.team.name,
          player: lastEvent.player.name,
          playerOut: {},
          description: lastEvent.detail,
          color: "#353535",
        }
        console.log("NOTIFICATION ACTIVE")
        if (lastEvent.detail == "Yellow Card") {
          highlight.color = "gold"
        }
        if (lastEvent == "Red Card") {
          highlight.color = "red"
        }
        if (lastEvent.type == "subst") {
          highlight.playerOut = lastEvent.assist.name
        }
        setCurretnHighlight(highlight)
        setIsHighLightActive(true)
        setTimeout(() => { setIsHighLightActive(false) }, 15000)
        setFixtureEvents(data[0].events)
        setFixture(data)
      }
      else {
        console.log("ERROR: DATA IS LOWER THAN CURRENT STATE");

      }
    }
    else {
      console.log("STILL WAITING")
      console.log(fixtureEvents.length)
      console.log(data[0].events.length)
    }
  }

  useEffect(() => {
    console.log("Fixture EVENTS: ", fixtureEvents);
  }, [fixtureEvents]);

  useEffect(() => {
    console.log("LineUp: ", lineUp);
  }, [lineUp]);

  useEffect(() => {
    if (statistics != null && statistics[0] != undefined && statistics[1] != undefined) {
      statisticsArrayHandler()
      console.log("statistics: ", formattedStatistics);
    }
  }, [statistics]);


  useEffect(() => {
    if (fixture != null) {
      console.log("Fixture: ", fixture)
      if (statisticsToggle && fixture?.length > 0) {
        getLineUpInformation(fixture[0].fixture.id);
        getStatisticsInformation(fixture[0].teams.away.id, fixtureId, fixture[0].teams.home.id)
        getStandingInformation(fixture[0].league.id, fixture[0].league.season)
      }

    }
  }, [fixture, statisticsToggle]);

  useEffect(() => {
    getEventsInformation();
  }, []);

  useEffect(() => { console.log(statisticsToggle) }, [statisticsToggle])

  useEffect(() => {
    if (fixtureEvents) {
      if (statisticsToggle) {
        const interval = setInterval(() => {
          let fixtureEventsObj = fixtureEvents
          getUpdatedFixture(fixtureEventsObj)
        }, 60000);
        return () => clearInterval(interval);
      }
    }
  }, [fixtureEvents, statisticsToggle])

  useEffect(() => {
    if (statisticsToggle) {
      getEventId()
    }
  }, [currentEvent, statisticsToggle])

  useEffect(() => {
    if (isStatisticsActive) {
      document.querySelector(".player-statistics")?.classList.add("active");
    } else {
      document.querySelector(".player-statistics")?.classList.remove("active");
    }

    // if(selectedStatistic == "statistic") {
    //     statisticsButtons[0].classList.add("active")
    // }
  });

  useEffect(() => {
    if (isHighlightActive) {
      document.querySelector(".highlight-card")?.classList.add("active");
    } else {
      document.querySelector(".highlight-card")?.classList.remove("active");
    }
  });

  return (
    <div className="highlight-container">
      {isHighlightActive ?
        <Highlight highlight={currentHighlight} />
        : <></>}
      {statisticsToggle ?
        (<div className="player-statistics">
          {isStatisticsActive ? (
            <>
              <div className="upper-nav">
                <div className="left-side">
                  <button
                    className="nav-button back-button"
                    onClick={statisticsHandler}
                  >
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1668 5.79183H3.54641L7.506 1.83225L6.50016 0.833496L0.833496 6.50016L6.50016 12.1668L7.49891 11.1681L3.54641 7.2085H12.1668V5.79183Z"
                        fill="black"
                      />
                    </svg>
                    <p>Back to game</p>
                  </button>
                </div>
                <div className="right-side">
                  <button
                    className="nav-button statistics-selector Statistics active"
                    value="Statistics"
                    onClick={statisticsTab}
                  >
                    <p>Statistics</p>
                  </button>
                  <button
                    className="nav-button statistics-selecto Squads"
                    onClick={squadsTab}
                  >
                    <p>Squads</p>
                  </button>
                  <button
                    className="nav-button statistics-selector Standings"
                    onClick={standingsTab}
                  >
                    <p>Standings</p>
                  </button>
                </div>
              </div>
              {selectedStatistic === "statistics" ? (
                <div className="main-content">
                  <div className="statistics-top-section">
                    <div className="left-club">
                      <div className="team-name">
                        <p>{fixture[0]?.teams.home.name}</p>
                      </div>
                      <div className="container">
                        <img
                          src={fixture[0]?.teams.home.logo}
                          alt="Home Team Logo"
                        />
                      </div>
                    </div>
                    <div className="score">
                      <h5>{fixture[0]?.fixture.status.elapsed} '</h5>
                      <p>{fixture[0]?.goals.home}-{fixture[0]?.goals.away}</p>
                    </div>
                    <div className="right-club">
                      <div className="container">
                        <img
                          src={fixture[0]?.teams.away.logo}
                          alt="Away Team Logo"
                        />
                      </div>
                      <div className="team-name">
                        <p>{fixture[0]?.teams.away.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="statistics-main-content">
                    {statistics ?
                      <div className="main-content-container">
                        {formattedStatistics?.map((value: any, index: number) =>
                        (<div className="ball-possession statistic-section" key={index}>
                          <div className="top-bar">
                            <div className="left">
                              <p>{value.statistic.team.home.value}</p>
                            </div>
                            <div className="center">{value.statistic.team.home.type}</div>
                            <div className="right">
                              <p>{value.statistic.team.away.value}</p>
                            </div>
                          </div>
                          <div className="bottom-bar">
                            <div className="home-team">
                              <div
                                className="percentageHandler"
                                style={{ width: statisticsPercentage(value.statistic.team.home.value, value.statistic.team.away.value).homeResult + "%", backgroundColor: "#" + fixture[0].lineups[0].team.colors.player.primary }}
                              ></div>
                            </div>
                            <div className="away-team">
                              <div
                                className="percentageHandler"
                                style={{ width: statisticsPercentage(value.statistic.team.home.value, value.statistic.team.away.value).awayResult + "%", backgroundColor: "#" + fixture[0].lineups[1].team.colors.player.primary }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        ))}
                      </div> :
                      <div className="not-available">
                        <h3>Statistics are not available right now</h3>
                      </div>
                    }
                  </div>
                </div>
              ) : (
                ""
              )}
              {selectedStatistic === "squads" ? (
                <div className="main-content">
                  {lineUp.length > 0 ? <div className="flex-container">
                    <div className="container">
                      <div className="team">
                        <div className="img-container">
                          <img
                            src={lineUp[0].team.logo}
                            alt="Home Team Logo"
                          />
                        </div>

                        <p>{lineUp[0].team.name}</p>
                      </div>
                      <div className="team-squad team-table">
                        <table>
                          <thead>
                            <tr>
                              <th>Nº</th>
                              <th>NAME</th>
                              <th>POS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lineUp[0].startXI.map(
                              //TEMPORARY ANY
                              (player: any, index: number) => (
                                <tr key={index}>
                                  <td>{player.player.number}</td>
                                  <td>
                                    <p>{player.player.name}</p>
                                  </td>
                                  <td>{player.player.pos}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="container">
                      <div className="team">
                        <div className="img-container">
                          <img
                            src={lineUp[1].team.logo}
                            alt=""
                          />
                        </div>
                        <p>{lineUp[1].team.name}</p>
                      </div>
                      <div className="team-squad team-table">
                        <table>
                          <thead>
                            <tr>
                              <th>Nº</th>
                              <th>NAME</th>
                              <th>POS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lineUp[1].startXI.map(
                              //TEMPORARY ANY
                              (player: any, index: number) => (
                                <tr key={index}>
                                  <td>{player.player.number}</td>
                                  <td>
                                    <p>{player.player.name}</p>
                                  </td>
                                  <td>{player.player.pos}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div> :
                    <div className="not-available">
                      <h3>Squads are not available right now</h3>
                    </div>}
                </div>
              ) : (
                ""
              )}
              {selectedStatistic === "standings" ? (
                <div className="main-content">
                  {standings.length > 0 ?
                    // <table id="standings-table">
                    //   <thead>
                    //     <tr>
                    //       <th>#</th>
                    //       <th>CLUBS</th>
                    //       <th>MP</th>
                    //       <th>W</th>
                    //       <th>D</th>
                    //       <th>L</th>
                    //       <th>GF</th>
                    //       <th>GA</th>
                    //       <th>GD</th>
                    //       <th>PTS</th>
                    //       <th>LAST 5</th>
                    //     </tr>
                    //   </thead>
                    //   <tbody>
                    //     {standings[0].league.standings[0].map((team: any, index: number) => (
                    //       <tr className="table-content" key={`team-${index}`} style={{ backgroundColor: team.team.id == fixture[0].teams.home.id || team.team.id == fixture[0].teams.away.id ? "#f6f4f2" : "", color: team.team.id == fixture[0].teams.home.id || team.team.id == fixture[0].teams.away.id ? "black" : "white" }}>
                    //         <td>{team.rank}</td>
                    //         <td>
                    //           <img src={team.team.logo} alt="Team Logo" />
                    //           {team.team.name}
                    //         </td>
                    //         <td>{team.all.played}</td>
                    //         <td>{team.all.win}</td>
                    //         <td>{team.all.draw}</td>
                    //         <td>{team.all.lose}</td>
                    //         <td>{team.all.goals.for}</td>
                    //         <td>{team.all.goals.against}</td>
                    //         <td>{Math.abs(team.all.goals.for - team.all.goals.against)}</td>
                    //         <td>{team.points}</td>
                    //         <td>
                    //           {lastFiveGamesHandler(team.form, index).map(
                    //             (game, idx) => <span key={`game-${index}-${idx}`}>{game}</span>
                    //           )}
                    //         </td>
                    //       </tr>
                    //     ))}
                    //   </tbody>
                    // </table>

                    <div>
                      <div className="head">
                        <div className="left">
                          <button className="cup-button group-stage active" onClick={groupStageTab}>
                            <p>Group Stage</p>
                          </button>
                        </div>
                        <div className="right">
                          <button className="cup-button last-16" onClick={last16Tab}>
                            <p>Last 16</p>
                          </button>
                          <button className="cup-button last-8" onClick={last8Tab}>
                            <p>Last 8</p>
                          </button>
                          <button className="cup-button last-4" onClick={last4Tab}>
                            <p>Last 4</p>
                          </button>
                          <button className="cup-button last-2" onClick={last2Tab}>
                            <p>Last 2</p>
                          </button>
                          <button className="cup-button final" onClick={finalTab}>
                            <p>Final</p>
                          </button>
                        </div>
                      </div>
                      {selectedStanding === "group-stage" ? (
                        <div className="content" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          {standings[0]?.league.standings.map((elem: any) => (
                            <table style={{ marginBottom: 50, marginTop: 25, width: "80%" }} id="standings-table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>CLUBS</th>
                                  <th>MP</th>
                                  <th>W</th>
                                  <th>D</th>
                                  <th>L</th>
                                  <th>GF</th>
                                  <th>GA</th>
                                  <th>GD</th>
                                  <th>PTS</th>
                                  <th>LAST 5</th>
                                </tr>
                              </thead>
                              <tbody>
                                {elem.map((team: any, index: number) => (
                                  <tr className="table-content" key={`team-${index}`} style={{ backgroundColor: team.team.id == fixture[0].teams.home.id || team.team.id == fixture[0].teams.away.id ? "#f6f4f2" : "", color: team.team.id == fixture[0].teams.home.id || team.team.id == fixture[0].teams.away.id ? "black" : "white" }}>
                                    <td>{team.rank}</td>
                                    <td>
                                      <img src={team.team.logo} alt="Team Logo" />
                                      {team.team.name}
                                    </td>
                                    <td>{team.all.played}</td>
                                    <td>{team.all.win}</td>
                                    <td>{team.all.draw}</td>
                                    <td>{team.all.lose}</td>
                                    <td>{team.all.goals.for}</td>
                                    <td>{team.all.goals.against}</td>
                                    <td>{Math.abs(team.all.goals.for - team.all.goals.against)}</td>
                                    <td>{team.points}</td>
                                    <td>
                                      {lastFiveGamesHandler(team.form, index).map(
                                        (game, idx) => <span key={`game-${index}-${idx}`}>{game}</span>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ))}
                        </div>
                      ) : ""}
                    </div>
                    :
                    <div className="not-available">
                      <h3>Standings are not available right now</h3>
                    </div>
                  }
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div className="upper-nav">
              <button className="nav-button" onClick={statisticsHandler}>
                <svg
                  width="14"
                  height="12"
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5451 11.5075C13.4282 11.7919 13.2099 11.8821 12.915 11.8785C12.1354 11.8687 11.355 11.8687 10.5754 11.8781C10.2551 11.8821 9.98713 11.7095 9.99065 11.2905C10.0012 9.9194 10.0008 8.54873 9.99026 7.17767C9.98752 6.80665 10.221 6.59278 10.5672 6.59987C11.3468 6.61562 12.1272 6.61444 12.9067 6.59987C13.2017 6.59435 13.4188 6.67628 13.5451 6.95277C13.5451 8.47035 13.5451 9.98912 13.5451 11.5075Z"
                    fill="black"
                  />
                  <path
                    d="M8.54362 6.24597C8.54362 7.92819 8.54362 9.61041 8.54362 11.2926C8.54362 11.6818 8.35704 11.8693 7.96666 11.87C7.1644 11.8708 6.36213 11.8712 5.55987 11.87C5.17184 11.8696 4.98369 11.6802 4.98369 11.2891C4.9833 7.91559 4.9833 4.54248 4.98369 1.16899C4.98369 0.780632 5.17575 0.591181 5.56339 0.590787C6.36565 0.590393 7.16792 0.589999 7.97018 0.590787C8.35625 0.591181 8.54362 0.782208 8.54362 1.17253C8.54362 2.86381 8.54362 4.55509 8.54362 6.24597Z"
                    fill="black"
                  />
                  <path
                    d="M3.55054 8.15099C3.55054 9.19868 3.54937 10.2464 3.55172 11.2941C3.55211 11.465 3.53529 11.6363 3.38704 11.7344C3.28299 11.8033 3.14687 11.8581 3.02444 11.8597C2.19127 11.8703 1.35771 11.8683 0.524543 11.8636C0.181106 11.8616 0 11.6667 0 11.3201C0 9.20695 0 7.09384 0 4.98033C0 4.63649 0.186974 4.44743 0.531584 4.44703C1.35615 4.44585 2.18071 4.44625 3.00488 4.44703C3.36787 4.44743 3.54898 4.62782 3.54976 4.99451C3.55133 6.04654 3.55054 7.09857 3.55054 8.15099Z"
                    fill="black"
                  />
                </svg>
                <p>View statistics</p>
              </button>
            </div>
          )}
        </div>) : <></>}
    </div>

  );
}

export default Statistics;
