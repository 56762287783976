import React from 'react';
import { Alert, IconButton, Collapse } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import parse from 'html-react-parser';

interface IStatusProps {
    message: string;
    open: boolean;
    error: boolean;
    setStatus: () => void;
}

function Status(props: IStatusProps) {
    const { message, open, error, setStatus } = props;

    let statusMessageElem = <span></span>;

    if (message === '') {
        return statusMessageElem;
    }

    statusMessageElem = (
        <Collapse in={open}>
            <Alert
                severity={error ? "error" : "success"}
                variant="outlined"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={setStatus}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {parse(message)}
            </Alert>
        </Collapse>
    );

    return statusMessageElem;
}

export default Status;