import React from 'react';
import { Socket } from 'socket.io-client';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { LiveStream } from 'model/model';
import { Typography } from '@mui/material';
import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';
import StreamThumb from 'components/sidebar/streamthumb/StreamThumb';

interface INewestVideos {
    thumbList: LiveStream[];
    socket: Socket<ServerToClientEvents, ClientToServerEvents>
    selectStream: (stream: LiveStream) => void;
    dispatchLiveStreams: (action: any) => void;
}

function NewestVideos(props: INewestVideos) {
    const { thumbList, socket, selectStream, dispatchLiveStreams } = props;

    if (thumbList.length === 0) {
        return (
            <Typography color="secondary" className="no-streams">
                No streams available...
            </Typography>
        );
    }

    return (
        <div className="stream-list">
            {thumbList.map((stream: LiveStream, index: number) =>
                (<StreamThumb 
                    key={stream.hash} stream={stream} socket={socket}
                    selectStream={selectStream} dispatchLiveStreams={dispatchLiveStreams} />)
            )}
        </div>
    );

    /*return (
        <TransitionGroup className="stream-list">
            {props.thumbList.map((stream: LiveStream, index: number) =>
                <CSSTransition
                    key={stream.hash}
                    classNames="thumbanim"
                    timeout={200}
                >
                    <StreamThumb stream={stream} />
                </CSSTransition>
            )}
        </TransitionGroup>
    );*/
}

export default NewestVideos;