import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Typography } from '@mui/material';
import { useHistory  } from 'react-router-dom';

import { UserActions } from 'redux/actions';
import { RootState } from 'redux/reducer';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const ACTIVE_CLASS = 'show';
const INACTIVE_CLASS = '';

function Dropdown(props: {}) {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();

    const [isToggled, setToggle] = useState(false);
    const toggleMenuClass = (isToggled ? ACTIVE_CLASS : INACTIVE_CLASS);

    const logout = () => {
        setToggle(false);

        dispatch(UserActions.updateUser(null));
        localStorage.removeItem('accessToken');

        history.push('/auth/login');
    }

    return (
        <div className="user-dropdown">
            <Link
                className="dropdown-toggle"
                href="#"
                variant="inherit"
                color="inherit"
                underline="none"
                onClick={() => {
                    setToggle(!isToggled)
                }}
            >
                <AccountCircleIcon className="user-icon" />

                <Typography className="navbar-username">
                    {user?.username}
                </Typography>

                <ArrowDropDownIcon className="user-arrow" />
            </Link>

            <div className={`dropdown-menu dropdown-menu-right ${toggleMenuClass}`}>
                {/*<Link
                    href="#"
                    className="dropdown-item"
                    onClick={() => setToggle(false)}
                >
                    <AccountCircleOutlinedIcon />
                    My profile
                </Link>

                <Link
                    href="#"
                    className="dropdown-item"
                    onClick={() => setToggle(false)}
                >
                    <LockOutlinedIcon />
                    My password
                </Link>

                <div className="dropdown-divider"></div>*/}

                <Link
                    href="#"
                    className="dropdown-item"
                    onClick={() => logout()}
                >
                    <LogoutOutlinedIcon />
                    Sign out
                </Link>
            </div>
        </div>
    );
}

export default Dropdown;