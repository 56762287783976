import React from 'react';

import PlayOffLogo from 'assets/logo/playoff-logo-big.png';
import { Typography } from '@mui/material';

interface IAuthLayout { 
    children: React.ReactNode,
    title: string,
    auth: boolean
};

function AuthLayout(props: IAuthLayout) {
    const formClassVerification = props.auth ? '' : ' verification-form';
   
    return (
        <div className="auth-container">
            <Typography className="page-title" variant="h1">
                {props.title}
            </Typography>
            <div className="form-box">
                <div className={`form-body${formClassVerification}`}>
                    <div className="img-banner">
                        <img src={PlayOffLogo} alt="PlayOff Logo" />
                    </div>

                    {props.children}

                </div>
            </div>
        </div>
    );
}

export default AuthLayout;
