import React from 'react';
import { Button } from '@mui/material';

const LiveBadge = (props: {}) => {
    return (
        <Button
            className="live-badge"
            startIcon={<span className="live-dot"></span>}
            disabled
        >
            Live
        </Button>
    );
}

export default LiveBadge;