import { createTheme } from '@mui/material/styles';

export const theme =
    createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    // Custom input text and input border on hover/focus
                    root: {
                        fontSize: 16,
                        fontWeight: 400,
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ccc'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#009933'
                        }
                    },
                    // Custom input border color and radius
                    notchedOutline: {
                        border: '1px solid #ccc',
                        borderRadius: 8,
                        '&:hover': {
                            border: '1px solid #ccc'
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    // Custom label
                    root: {
                        fontSize: 16,
                        fontWeight: 400,
                        color: 'rgb(102, 102, 102)',
                        '&:not(.MuiInputLabel-shrink)': {
                            WebkitTransform: 'translate(17px, 16px) scale(1)',
                            MozTransform: 'translate(17px, 16px) scale(1)',
                            msTransform: 'translate(17px, 16px) scale(1)',
                            transform: 'translate(17px, 16px) scale(1)'
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    // Add horizontal padding to input
                    input: {
                        padding: '16.5px 17px !important' 
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: 16,
                        fontWeight: 400,
                    }
                }
            }
        },
        typography: {
            fontFamily: ['Heebo', 'sans-serif'].join(','),
            h1: {
                fontSize: 28
            },
            h3: {
                fontSize: 24,
                letterSpacing: '0.02em',
                fontWeight: 500,
            },
            body1: {
                fontSize: 17,
                fontWeight: 500
            }
        },
        palette: {
            background: {
                default: '#FFFFFF'
            },

            primary: {
                main: '#009933'
            },

            secondary: {
                main: '#8E8E8E'
            },

            error: {
                main: '#ff7070'
            },

            text: {
                primary: "#000000"
            }
        }
    });