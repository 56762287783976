import React, { useState } from 'react';
import { Button, Grid, Link, MenuItem, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { History } from 'history';

import { signUp } from 'api/user';
import { to } from 'utils/utils';
import AuthLayout from 'templates/authlayout/AuthLayout';
import Status from 'components/common/status/Status';
import TextInput from 'components/common/textinput/TextInput';
import moment from 'moment';
import Loading from 'components/common/loading/Loading';

interface IRegisterProps {
    history: History
};

function Register(props: IRegisterProps) {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [birthDate, setBirthDate] = useState(null);
    const [gender, setGender] = useState('');
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [registerStatus, setRegisterStatus] = useState({
        message: '',
        open: true,
        error: false
    });

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            setPassword('');
            setConfirmPassword('');

            const errorResponse = 'Passwords don\'t match';
            setRegisterStatus({
                open: true,
                message: errorResponse,
                error: true
            });
            return;
        }

        setLoading(true);
        setRegisterStatus({
            open: false,
            message: '',
            error: false
        });

        const date = birthDate ? moment(birthDate).format('MM/DD/YYYY') : '';
        const [err, result] = await to(signUp(email, username, gender, date, password))

        if (err || !result) {
            setPassword('');
            setConfirmPassword('');
            setLoading(false);

            const errorResponse = err?.data;
            setRegisterStatus({
                open: true,
                message: errorResponse,
                error: true
            });
        } else {
            setEmail('');
            setUsername('');
            setPassword('');
            setConfirmPassword('');
            setGender('');
            setBirthDate(null);
            setLoading(false);

            setRegisterStatus({
                open: true,
                message: result.message,
                error: false
            });
        }
    }

    const handleClose = () => {
        setRegisterStatus((prevState) => ({
            ...prevState,
            open: false,
        }));
    }

    let formClass = "auth-form";
    if (loading) { formClass += " form-loading"; }

    return (
        <AuthLayout title="Create an account" auth={true}>
            <div className="register-form-container">
                {loading ? <Loading /> : <></>}
                <form
                    className={formClass}
                    onSubmit={handleSubmit}
                >
                    <Status
                        message={registerStatus.message}
                        open={registerStatus.open}
                        error={registerStatus.error}
                        setStatus={handleClose}
                    />

                    <Grid container>

                        <Grid className="register-item" item xs={12}>
                            <TextInput
                                type="text"
                                label="Email"
                                value={email}
                                setInputValue={setEmail}
                                disabled={false}
                                required={true}
                                autoComplete="email"
                            />
                        </Grid>

                        <Grid className="register-item" item xs={12}>
                            <TextInput
                                type="text"
                                label="Username"
                                value={username}
                                setInputValue={setUsername}
                                disabled={false}
                                required={true}
                                autoComplete="username"
                            />
                        </Grid>

                        <Grid className="register-item" item xs={12}>
                            <TextInput
                                id="gender"
                                label="Gender"
                                select
                                defaultValue=""
                                value={gender}
                                setInputValue={setGender}
                                disabled={false}
                                required={true}
                            >
                                <MenuItem key={0} value={"F"}>
                                    Female
                                </MenuItem>
                                <MenuItem key={1} value={"M"}>
                                    Male
                                </MenuItem>
                                <MenuItem key={2} value={"O"}>
                                    Other
                                </MenuItem>
                            </TextInput>
                        </Grid>

                        <Grid className="register-item" item xs={12}>
                            <TextInput
                                type="number"
                                label="Birth Date"
                                value={birthDate}
                                setInputValue={setBirthDate}
                                disabled={false}
                                required={true}
                                date={true}
                                maxDate={moment()}
                            />
                        </Grid>

                        <Grid className="register-item item-margin-right" item xs={6}>

                            <TextInput
                                type="password"
                                label="Enter Password"
                                value={password}
                                setInputValue={setPassword}
                                disabled={false}
                                required={true}
                                autoComplete="current-password"
                            />

                        </Grid>

                        <Grid className="register-item item-margin-left" item xs={6}>

                            <TextInput
                                type="password"
                                label="Confirm Password"
                                value={confirmPassword}
                                setInputValue={setConfirmPassword}
                                disabled={false}
                                required={true}
                                autoComplete="current-password"
                            />

                        </Grid>

                        <Button
                            className="auth-btn"
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Register
                        </Button>


                    </Grid>
                </form>

                <div className="auth-link-container">
                    <Typography className="no-account" component="span">
                        Already have an account? </Typography>
                    <Link
                        className="register-link"
                        component={NavLink}
                        to="/auth/login"
                    >
                        Login
                    </Link>
                </div>
            </div>
        </AuthLayout>
    );
}

export default Register;