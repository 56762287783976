import React, { useEffect, useRef, useState } from 'react';
import mpegts from 'mpegts.js';
import { Button, ButtonBase, Slider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Player } from 'model/model';
import {
    formatSecondsToTime,
    toggleFullScreen
} from 'utils/utils';
import LiveBadge from 'components/common/livebadge/LiveBadge';
import { LiveEventInfo } from 'model/model';

import { ReactComponent as VolumeUpIcon } from 'assets/icons/volume-up.svg';
import { ReactComponent as VolumeDownIcon } from 'assets/icons/volume-down.svg';
import { ReactComponent as MuteIcon } from 'assets/icons/volume-mute.svg';
import { ReactComponent as FullscreenIcon } from 'assets/icons/fullscreen.svg';
import { ReactComponent as StatsIcon } from 'assets/icons/stats.svg';

interface IVideoPlayer {
    address: string;
    videoSrc: string;
}

interface ILiveGameProps {
    eventInfo: LiveEventInfo | null
}

const useStyles = makeStyles((theme: any) => ({
    customButton: {
        position: 'absolute',
        top: 24,
        left: 24,
        padding: "10px 20px",
        zIndex: 1,
        textTransform: 'capitalize',
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        borderRadius: 39,
        fontSize: 16,
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
        }
    }
}));

const VideoPlayer = (props: IVideoPlayer) => {
    const videoRef: React.RefObject<any> = useRef(null);
    const videoPlayerRef: React.RefObject<any> = useRef(null);
    const timeDisplayRef: React.RefObject<any> = useRef(null);
    const [muted, setMuted] = useState(true);
    const [volume, setVolume] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!videoRef.current) { return; }
            timeDisplayRef.current!.innerHTML = formatSecondsToTime(
                videoRef.current!.currentTime
            );
        }, 50);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        let player: Player | undefined;
        if (videoRef && videoRef.current) {
            const video = videoRef.current;
            player = mpegts.createPlayer({
                type: 'flv',
                isLive: true,
                url: '/video/soccer-sample-stream.ts'
            });
            player.attachMediaElement(video);

            player.muted = true;
            player.load();
            player.play();
        }

        return () => {
            if (player) {
                // player.pause();
                player.unload();
                player.detachMediaElement();
                player.destroy();
            }
        }
    }, [videoRef, props.address, props.videoSrc]);

    useEffect(() => {
        if (muted) {
            setVolumeValue(0);
        } else if (videoRef.current.volume === 0) {
            setVolumeValue(1);
        }
    }, [muted]);

    /* const handleVideoLoad = (event: any) => {
        setMutedValue(false);
        setVolumeValue(1);
    } */

    const toggleFullScreenHandler = (event: any) => {
        event.stopPropagation();

        toggleFullScreen(videoPlayerRef.current);
    }

    const preventDragHandler = (event: any) => {
        event.preventDefault();
    }

    const toggleMute = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (event instanceof Event) { event.stopPropagation(); }

        setMutedValue(!muted);
    }

    const handleVolumeChange = (event: Event, newVolume: number | number[]) => {
        if (newVolume === 0) { setMutedValue(true); }
        else { setMutedValue(false); }

        const volume = Array.isArray(newVolume) ? newVolume[0] : newVolume;
        setVolumeValue(volume);
    }

    const setMutedValue = (newMuted: boolean) => {
        videoRef.current.muted = newMuted;
        setMuted(newMuted);
    }

    const setVolumeValue = (newVolume: number) => {
        setVolume(newVolume);
        videoRef.current.volume = newVolume;
    }

    const VolumeIcon = (volume > 0.5 ? <VolumeUpIcon /> : <VolumeDownIcon />);

    return (
        <div
            ref={videoPlayerRef}
            className="player-container"
            onDoubleClick={toggleFullScreenHandler}
        >
            {/*<Button
                className={classes.customButton}
                variant="contained"
                color="secondary"
                startIcon={<StatsIcon />}
            >
                View Statistics
    </Button>*/}

            <video
                className="player-video"
                loop
                ref={videoRef}
            />

            <div className="player-options">
                <div className="options-wrapper">
                    <LiveBadge />

                    <div className="player-time">
                        <Typography
                            ref={timeDisplayRef}
                            className="player-current-time"
                        >
                            00:00
                        </Typography>
                    </div>

                    <div className="volume-container">
                        <ButtonBase
                            className="volume-icon"
                            onDragStart={preventDragHandler}
                            onClick={toggleMute}
                        >
                            {muted ?
                                <MuteIcon /> :
                                VolumeIcon
                            }
                        </ButtonBase>

                        <Slider
                            className="volume-slider"
                            aria-label="Volume"
                            value={volume}
                            min={0}
                            step={0.01}
                            max={1}
                            onChange={handleVolumeChange}
                        />
                    </div>

                    <div className="space-fill" />

                    <ButtonBase
                        className="player-icon"
                        onDragStart={preventDragHandler}
                        onClick={toggleFullScreenHandler}
                    >
                        <FullscreenIcon />
                    </ButtonBase>
                </div>
            </div>
        </div>
    );
}

export default VideoPlayer;