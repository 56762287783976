import React from 'react';
import io from 'socket.io-client';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider } from 'react-redux';

import AppRouter from 'AppRouter';
import store from 'redux/store';
import settings from 'api/config.js';
import { theme } from 'templates/themes';

import 'css/style.css';

const socket = io(settings.API_WS_PROTOCOL + '://' + settings.API_IP, { path: settings.API_PATH + '/socket.io' });

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={store}>
                    <AppRouter socket={socket} />
                </Provider>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
