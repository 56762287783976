import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'pages/login/Login';
import Register from 'pages/register/Register';
import { isLoggedIn } from 'utils/auth';


interface IAuthRouter { 
};

function AuthRouter(props: IAuthRouter) {
    if (isLoggedIn()) {
        return <Redirect to={{ pathname: "/" }} />;
    }

    return (
        <Switch>
            <Route exact path="/auth/login" component={Login} />
            <Route exact path="/auth/register" component={Register} />
            <Redirect to="/auth/login" />
        </Switch>
    );
}

export default AuthRouter;
