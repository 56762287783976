import React, { useEffect, useState, useRef, useCallback, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { Socket } from 'socket.io-client';
import { Typography } from '@mui/material';
import moment from 'moment';

import { getUserByStream } from 'api/user';
import { getNextEvents } from 'api/event';
import FlvPlayer from 'components/videoplayer/VideoPlayer';
import Loading from 'components/common/loading/Loading';
import Placeholder from 'components/videoplayer/placeholder/Placeholder';
import Sidebar from 'components/sidebar/Sidebar';
import Statistics from 'components/videoplayer/placeholder/Statistics';
import Highlight from 'components/videoplayer/placeholder/highlight/Highlight';

import { SidebarOptions } from 'model/enum';
import { LiveEventInfo, LiveStream } from 'model/model';
import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';
import { liveStreamReducer } from 'pages/livegame/StreamReducer';
import { RootState } from 'redux/reducer';
import { getUsername, to } from 'utils/utils';

import { ReactComponent as ViewsIcon } from 'assets/icons/views-icon.svg';
import { ReactComponent as FullStarIcon } from 'assets/icons/full-star.svg';
import { ReactComponent as BigStarIcon } from 'assets/icons/big-star.svg';

interface ILiveGameProps {
    socket: Socket<ServerToClientEvents, ClientToServerEvents>
};

function LiveGame(props: ILiveGameProps) {
    const socket = props.socket;

    const user = useSelector((state: RootState) => state.user);

    const [isLoading, setIsLoading] = useState(true);
    const [serverAddress, setServerAddress] = useState('');
    const [eventInfo, setEventInfo] = useState(null as LiveEventInfo | null);
    const [sidebarOption, setSidebarOption] = useState(SidebarOptions.VIDEOS);
    const [liveStreams, dispatch] = useReducer(liveStreamReducer, {
        current: null as LiveStream | null,
        list: [] as LiveStream[]
    });

    // True if video player contains a stream.
    const mainRef = useRef('');
    // Length of thumbnail list.
    const listRef = useRef(0);
    // Has run initial config
    const hasFetchedData = useRef(false);

    /********* Socket Client -> Server - Inform server about increasing / decreasing counter *********/
    const informUserView = useCallback((hashStream: string, start: boolean) => {
        console.log(' -- Inform User View --', start ? 'Increase' : 'Decrease', 'counter for:', hashStream);

        const messageType = start ? 'live' : 'endLive';
        const message = {
            data: hashStream
        };
        socket.emit(messageType, message);
    }, [socket]);

    /********* Component Did Mount - Retrieve event information from API *********/
    const getEventInformation = useCallback(async () => {
        const [err, result] = await to(getNextEvents());

        if (err || !result) {
            console.error(' -- Error retrieving ongoing event information:', err);
            return;
        }

        let nextEv = null;
        if (result.nextEvents.length > 0) { nextEv = result.nextEvents[0]; }

        setEventInfo({
            current_name: result.eventInfo ? result.eventInfo.hometeamname + " vs " + result.eventInfo.awayteamname : '',
            eventInfo: result.eventInfo,
            next_event: nextEv ? nextEv : null,
            is_ongoing: result.ongoingEvent
        });
    }, []);

    /********* Component Did Mount - Initialize client information and handle web socket events *********/
    useEffect(() => {
        if (hasFetchedData.current) { return; }

        socket.emit('initLivePlayer');
        socket.emit('join', 'consumers');

        socket.on('connect_error', function (err) {
            console.error(' -- Socket Client -- Error connecting to server:', err);
        });

        socket.on('initPlayer', (message) => {
            setServerAddress(message.mediaServer.Address);
            setIsLoading(false);

            console.log(' -- Init Player -- Stream list:', message.streamList);

            if (message.streamList.length === 0) { return; }

            if (!mainRef.current) {
                message.streamList.sort((a, b) => {
                    return moment.utc(b.created_at).diff(moment.utc(a.created_at))
                });

                const mainStream = message.streamList[0];
                const hashStream = mainStream.hash;
                informUserView(hashStream, true);

                dispatch({
                    id: 'setLiveStreams',
                    stream: mainStream,
                    list: message.streamList.slice(1)
                });
            }
        });

        socket.on('newStream', (stream) => {
            console.log(' -- New Stream -- New stream received:', stream);

            if (listRef.current === 0 && !mainRef.current) {
                console.log(' -- New Stream -- Set main stream:', stream);
                informUserView(stream.hash, true);

                dispatch({
                    id: 'setMainStream',
                    stream: stream,
                });
            } else {
                console.log(' -- New Stream -- Add stream to list:', stream);

                dispatch({
                    id: 'addStreamList',
                    stream: stream,
                });
            }
        });

        socket.on('sendPreview', (message) => {
            dispatch({
                id: 'updatePreview',
                stream: message.stream,
                imageData: message.imageData
            });
        });

        socket.on('deleteThumb', (hashStream) => {
            dispatch({
                id: 'deleteStream',
                hashStream: hashStream,
                informUserView: informUserView
            });
        });

        socket.on('counter', (message) => {
            dispatch({
                id: 'updateCounter',
                hashStream: message.hashStream,
                counter: message.counter
            });
        });

        // Retrieve event information from MOGPLAY API.
        getEventInformation();
        hasFetchedData.current = true;
    }, [socket, liveStreams, getEventInformation, informUserView]);

    useEffect(() => {
        return () => {
            if (mainRef.current) { informUserView(mainRef.current, false); }
        }
    }, [informUserView]);

    /********* Live Streams - Update live stream info *********/
    const updateStreamInfo = async (newStream: LiveStream, mainStream: boolean) => {
        const hashStream = newStream.hash;
        const [err, result] = await to(getUserByStream(hashStream));

        if (err || !result) {
            console.error(' -- Error retrieving info for thumbnail with hash', hashStream, ':', err);
            return;
        }

        if (mainStream) {
            newStream.ready = true;
            newStream.favorite = result.favorite;
            // newStream.username = result.username ? result.username : 'Anonymous';

            dispatch({
                id: 'setMainStream',
                stream: newStream
            });
        } else {
            dispatch({
                id: 'updateStreamInfo',
                hashStream: hashStream,
                favorite: result.favorite
            });
        }
    }

    useEffect(() => {
        const currentStream = liveStreams.current;
        const thumbList = liveStreams.list;


        // Update ref values to current ones.
        mainRef.current = currentStream ? currentStream.hash : '';
        listRef.current = thumbList.length;

        // If stream thumbnail is not yet ready, obtain additional information.
        const streamIndex = thumbList.findIndex((thumb: LiveStream) => thumb.ready === false);
        if (streamIndex !== -1) {
            const newThumb = thumbList[streamIndex];
            updateStreamInfo(newThumb, false);
        }

        // If main stream is not yet ready, obtain additional information.
        if (currentStream?.ready === false) {
            const newStream = { ...currentStream };
            updateStreamInfo(newStream, true);
        }

    }, [liveStreams]);

    /********* Live Streams - Handle main stream select *********/
    const selectStream = (stream: LiveStream) => {
        dispatch({
            id: 'selectStream',
            stream: stream,
            informUserView: informUserView
        });
    }

    const toggleFavorites = (stream: LiveStream) => {
        console.log(' -- Toggle favorites -- Stream', stream.hash, 'is favorite:', !stream.favorite);

        let currentStream = { ...stream };
        const favoriteValue = !stream.favorite;
        currentStream.favorite = favoriteValue;
        dispatch({
            id: 'setMainStream',
            stream: currentStream
        });

        const toggleFavorites = {
            type: favoriteValue ? 'create' : 'delete',
            hashStream: currentStream.hash,
            userId: user.id
        };

        socket.emit('favorites', toggleFavorites);
    }




    const currentName = eventInfo?.current_name;
    const nextEventName = eventInfo?.next_event?.name;
    const eventName = currentName ? currentName : nextEventName;


    if (isLoading) { return <Loading />; }

    return (
        <section className="fan-dashboard-section">
            <div className="player-view">
                {/*liveStreams.current ?*/}
                    <div className="stream-container">
                        <Statistics />

                        <FlvPlayer address={serverAddress} videoSrc={liveStreams?.current?.hash} />
                    </div>
                {/*    :
                    <div className="stream-container">
                        <Statistics />

                        <Placeholder eventInfo={eventInfo} />
                    </div>

                */}


                <div className="stream-footer">
                    {liveStreams.current ?
                        <div className="stream-info-right">
                            <div className="views-container">
                                <ViewsIcon />
                                <Typography className="views-txt" color="primary">
                                    {liveStreams.current.counter} views
                                </Typography>
                            </div>

                            <Button
                                className="save-favorites"
                                variant="contained"
                                color="primary"
                                startIcon={liveStreams.current.favorite ? <FullStarIcon /> : <BigStarIcon />}
                                onClick={() => toggleFavorites(liveStreams.current)}
                            >
                                {liveStreams.current.favorite ? 'Remove' : 'Save'}
                            </Button>
                        </div>
                        : <></>
                    }

                    <div className="stream-info-left">
                        <div className="cont">
                            <img src={eventInfo?.eventInfo?.hometeamlogo} alt="" />
                            <Typography
                                variant="h3"
                            >
                                {eventName}
                            </Typography>
                            <img src={eventInfo?.eventInfo?.awayteamlogo} alt="" />
                        </div>


                        {liveStreams.current ? <Typography
                            className="stream-user"
                            color="secondary"
                            component="p"
                        >
                            By {getUsername(liveStreams.current.hash, liveStreams.current.username, liveStreams.current.identifier)}
                        </Typography> : <></>}
                    </div>

                </div>
            </div>

            <Sidebar
                sidebarOption={sidebarOption}
                thumbList={liveStreams.list}
                socket={socket}
                selectStream={selectStream}
                setSidebarOption={setSidebarOption}
                dispatchLiveStreams={dispatch}
            />
        </section>

    );
}

export default LiveGame;