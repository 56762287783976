import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Link, Typography } from '@mui/material';
import { History } from 'history';

import { signIn } from 'api/auth';
import { to } from 'utils/utils';
import AuthLayout from 'templates/authlayout/AuthLayout';
import Status from 'components/common/status/Status';
import TextInput from 'components/common/textinput/TextInput';

import { UserActions } from 'redux/actions';

interface ILoginProps {
    history: History
};

function Login(props: ILoginProps) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState({
        message: '',
        open: true,
        error: true
    });

    const dispatch = useDispatch();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setLoginStatus({
            open: false,
            message: '',
            error: false
        });

        const [err, result]  = await to(signIn(username, password));
        if (err || !result) {
            const errorResponse = err?.data;
            setPassword('');
            setLoginStatus({
                open: true,
                message: errorResponse,
                error: true
            });
        } else {
            setUsername('');
            setPassword('');
    
            dispatch(UserActions.updateUser(result.user));
    
            const token = result.token;
            localStorage.setItem('accessToken', token);
    
            props.history.push('/');
        }
    }

    const handleClose = () => {
        setLoginStatus((prevState) => ({
            ...prevState,
            open: false,
        }));
    }

    return (
        <AuthLayout title="Welcome back" auth={true}>
            <div className="login-form-container">
                <form
                    className="auth-form"
                    onSubmit={handleSubmit}
                >

                    <Status
                        message={loginStatus.message}
                        open={loginStatus.open}
                        error={loginStatus.error}
                        setStatus={handleClose}
                    />

                    <TextInput
                        type="text"
                        label="Username"
                        value={username}
                        setInputValue={setUsername}
                        disabled={false}
                        required={true}
                        autoComplete="username"
                    />

                    <TextInput
                        type="password"
                        label="Password"
                        value={password}
                        setInputValue={setPassword}
                        disabled={false}
                        required={true}
                        autoComplete="current-password"
                    />

                    <Button
                        className="auth-btn"
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        Login
                    </Button>
                </form>

                <div className="auth-link-container">
                    <Typography className="no-account" component="span">Don't have an account? </Typography>
                    <Link 
                        className="register-link" 
                        component={NavLink}
                        to="/auth/register"
                    >
                        Register
                    </Link>
                </div>
            </div>
        </AuthLayout>
    );
}

export default Login;