import { HTTPMethod, Resource } from 'model/enum';
import { GetUserByStreamResponse, AccountValidationResponse, RegisterResponse } from 'model/response';
import { apiInfo, fetchRequest } from './api';

export const getUserByStream = (hashStream: string): Promise<GetUserByStreamResponse> => {
    return fetchRequest(
        HTTPMethod.GET,
        `${apiInfo.base}/${Resource.STREAMS}/${hashStream}`
    );
}

export const validateAccount = (token: string): Promise<AccountValidationResponse> => {
    return fetchRequest(
        HTTPMethod.PUT,
        `${apiInfo.base}/${Resource.USERS}/verify/${token}`
    )
}

export const signUp = (email: string, username: string, gender: string, birthdate: string, password: string): Promise<RegisterResponse> => {
    const body = {
        email: email,
        name: username,
        gender: gender,
        birthdate: birthdate,
        password: password
    };

    return fetchRequest(
        HTTPMethod.POST,
        `${apiInfo.base}/${Resource.USERS}/register`,
        body
    );
}