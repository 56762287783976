import React from 'react';
import { Socket } from 'socket.io-client';
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';

import NewestVideos from 'components/sidebar/newestvideos/NewestVideos';
import FavoriteVideos from 'components/sidebar/favoritevideos/FavoriteVideos';
import { LiveStream, SidebarList } from 'model/model';
import { SidebarOptions } from 'model/enum';
import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';

import { ReactComponent as CameraIcon } from 'assets/icons/camera-icon.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat-icon.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star-icon.svg';
import LiveChat from 'components/livechat/LiveChat';

const SIDEBAR_LIST: SidebarList = {
    videos: {
        title: 'Newest Videos',
        button: (<><CameraIcon /> Videos</>)
    },
    favorites: {
        title: 'Favorites',
        button: (<><StarIcon /> Favorites</>)
    },
    chat: {
        title: 'Live Chat',
        button: (<><ChatIcon /> Live Chat</>)
    }
}

interface ISidebarProps {
    sidebarOption: SidebarOptions;
    thumbList: LiveStream[];
    socket: Socket<ServerToClientEvents, ClientToServerEvents>
    setSidebarOption: (value: SidebarOptions) => void;
    selectStream: (value: LiveStream) => void;
    dispatchLiveStreams: (action: any) => void;
}

function Sidebar(props: ISidebarProps) {
    const { sidebarOption, thumbList, socket, setSidebarOption, selectStream, dispatchLiveStreams } = props;

    /********* HANDLE SIDEBAR OPTION SELECT *********/
    const handleSidebarOptionSelect = (event: React.MouseEvent<HTMLElement>, value: string) => {
        if (value !== null) { setSidebarOption(value as SidebarOptions); }
    }

    const getSidebarContent = () => {
        const NewestVideosElem = (<NewestVideos
            thumbList={thumbList} socket={socket}
            selectStream={selectStream} dispatchLiveStreams={dispatchLiveStreams} />);
        
        const FavoriteVideosElem = (<FavoriteVideos
            thumbList={thumbList} socket={socket} 
            selectStream={selectStream} dispatchLiveStreams={dispatchLiveStreams} />);

        const LiveChatElem = (
            <LiveChat socket={socket} />);

        switch (sidebarOption) {
            case SidebarOptions.VIDEOS:
                return NewestVideosElem;
            case SidebarOptions.FAVORITES:
                return FavoriteVideosElem;
            case SidebarOptions.CHAT:
                return LiveChatElem;
            default:
                return <></>;
        }
    }

    return (
        <div className="sidebar">

            <ToggleButtonGroup
                className="sidebar-options"
                color="primary"
                value={sidebarOption}
                exclusive
                onChange={handleSidebarOptionSelect}
            >
                {Object.keys(SIDEBAR_LIST).map((option, index) =>
                    <ToggleButton
                        key={index}
                        className="sidebar-toggle"
                        value={option}
                    >
                        {SIDEBAR_LIST[option].button}
                    </ToggleButton>
                )}
            </ToggleButtonGroup>

            <Typography
                className="toggle-title"
                component="p"
            >
                {SIDEBAR_LIST[sidebarOption].title}
            </Typography>

            {getSidebarContent()}
        </div>
    );
}

export default Sidebar;