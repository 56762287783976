import { User } from 'model/model';

export interface Action {
  type: string;
  payload: any;
}

export const ACTION_TYPES = {
  USER: {
    UPDATE_USER: 'UPDATE_USER',
  }
};

export class UserActions {
  static updateUser = (user: User | null): Action => ({
    type: ACTION_TYPES.USER.UPDATE_USER,
    payload: user,
  });
}
