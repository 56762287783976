import React, { useCallback, useEffect, useState, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import AuthLayout from 'templates/authlayout/AuthLayout';
import Loading from 'components/common/loading/Loading';
import { validateAccount } from 'api/user';
import { to } from 'utils/utils';

interface IVerificationParams {
    token: string
}

function Verification(props: {}) {
    const [loading, setLoading] = useState(true);
    const [header, setHeader] = useState('');
    const [description, setDescription] = useState('');

    const params = useParams<IVerificationParams>();
    const hasValidatedData = useRef(false);

    const verifyAccountAPI = useCallback(async () => {
        const { token } = params;

        const [err, result] = await to(validateAccount(token));
        if (err || !result) {
            console.error(' -- Error validating user account', err);
            return;
        }

        setHeader(result.header);
        setDescription(result.content);
        setLoading(false);
    }, [params])

    useEffect(() => {
        if (hasValidatedData.current) { return; }

        verifyAccountAPI();
    }, [verifyAccountAPI]);

    if (loading) {
        return (
            <div className="auth-container">
                <div className="form-box">
                    <div className="form-body">
                        <Loading />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <AuthLayout title="Account validation" auth={false}>
            <div className="validation-container">
                <Typography className="validation-title" variant="h1">
                    {header}
                </Typography>

                <Typography className="validation-subtitle" color="secondary">
                    {description}
                </Typography>

                <Button
                    className="auth-btn"
                    variant="contained"
                    color="primary"
                    component={NavLink}
                    to="/"
                >
                    Go to PlayOff
                </Button>
            </div>
        </AuthLayout>
    );
}

export default Verification;