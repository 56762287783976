import React from 'react';
import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
    selectInput: {
        paddingLeft: 20,
        '&:focus': {
            background: 'unset'
        }
    },
    selectIcon: {
        marginRight: 12
    },
    customInput: {
        // Add more padding to calendar button
        '& .MuiButtonBase-root': {
            marginRight: -5,
        },
    }
}));

function TextInput(props: any) {
    const {
        id, type, name, value, setInputValue, label, disabled, required,
        date, select, children, error, helperText, ...otherProps
    } = props;

    const classes = useStyles();

    const InputElement = (
        <TextField
            className={classes.customInput}
            type={type}
            name={name}
            value={value}
            label={label}
            fullWidth
            disabled={disabled}
            required={required ? true : false}
            onChange={(e: any) => setInputValue(e.target.value)}
            InputLabelProps={{ required: false }}
            variant="outlined"
            {...otherProps}
        />
    );

    const DateElement = (
        <DatePicker
            label={label}
            value={value}
            onChange={(value: any) => setInputValue(value)}
            {...otherProps}
            renderInput={(params: any) => (
                <TextField
                    className={classes.customInput}
                    required={required ? true : false}
                    InputLabelProps={{ required: false }}
                    variant="outlined"
                    {...params}
                />
            )}
        />
    );

    const SelectElement = (<>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
            classes={{ select: classes.selectInput, icon: classes.selectIcon }}
            className={classes.customInput}
            labelId={id}
            label={label}
            value={value}
            onChange={(e: any) => setInputValue(e.target.value)}
            displayEmpty
            variant="outlined"
        >
            {children}
        </Select>
    </>);

    const getInputElement = () => {
        if (select) {
            return SelectElement;
        } else if (date) {
            return DateElement;
        } else {
            return InputElement;
        }
    }

    return (
        <FormControl className="form-input" error={error} fullWidth>
            {getInputElement()}
        </FormControl>
    );
}

export default TextInput;