import moment from 'moment';
import { LiveStream } from 'model/model';

interface ILiveStreamsState {
    current: LiveStream,
    list: LiveStream[]
}

/*type Action =
 | { id: 'setLiveStreams', stream: LiveStream, list: LiveStream[] }
 | { id: 'addStreamList', stream: LiveStream }
 | { id: 'setMainStream', stream: LiveStream }
 | { id: 'updatePreview', stream: LiveStream, imageData: string }
 | { id: 'updateStreamInfo', hashStream: string, favorite: boolean }
 | { id: 'deleteStream', hashStream: string, informUserView: any }
 | { id: 'updateCounter', hashStream: string, counter: number }
 | { id: 'selectStream', stream: LiveStream, informUserView: any};*/

export const liveStreamReducer = (state: ILiveStreamsState, action: any) => {
    switch (action.id) {
        case 'setLiveStreams': {
            return {
                current: action.stream,
                list: action.list
            }
        }
        case 'addStreamList': {
            return {
                ...state,
                list: [action.stream, ...state.list]
            }
        }
        case 'setMainStream': {
            return {
                ...state,
                current: action.stream
            };
        }
        case 'updatePreview': {
            const streamIndex = state.list.findIndex(thumb => thumb.hash === action.stream.hash);
            const newThumbs = state.list.slice();

            if (streamIndex !== -1) {
                newThumbs[streamIndex].imageData = action.imageData;

                return {
                    ...state,
                    list: newThumbs
                };
            } else return state;
        }
        case 'updateStreamInfo': {
            const streamIndex = state.list.findIndex(thumb => thumb.hash === action.hashStream);
            const newThumbs = state.list.slice();

            if (streamIndex !== -1) {
                newThumbs[streamIndex].ready = true;
                newThumbs[streamIndex].favorite = action.favorite;

                return {
                    ...state,
                    list: newThumbs
                };
            } else return state;
        }
        case 'deleteStream': {
            const stream = state.current;
            let currentStream = { ...stream } as LiveStream | null;
            let currentThumbs = state.list.slice();

            if (!state.current && currentThumbs.length === 0) { return state; }

            currentThumbs = currentThumbs.filter(thumb => thumb.hash !== action.hashStream);

            // If current stream was deleted
            if (currentStream?.hash === action.hashStream) {
                currentStream = null;

                action.informUserView(action.hashStream, false);

                if (!currentStream && currentThumbs.length > 0) {
                    currentStream = currentThumbs[0];
                    currentThumbs = currentThumbs.filter(thumb => thumb.hash !== currentStream?.hash);

                    action.informUserView(currentStream.hash, true);
                }
            }

            const newState = {
                current: currentStream,
                list: currentThumbs
            };

            console.log(` -- Delete Thumbnail -- New state [${action.hashStream}]:`, newState, new Date());

            return newState;
        }
        case 'updateCounter': {
            const prevMainStream = state.current;
            const thumbList = state.list;
            const currentStream = { ...prevMainStream } as LiveStream;
            const streamIndex = thumbList.findIndex(s => s.hash === action.hashStream);

            if (currentStream.hash === action.hashStream) {
                currentStream.counter = action.counter;

                const newState = {
                    ...state,
                    current: currentStream
                };

                return newState;
            } else if (streamIndex !== -1) {
                const newThumbs = thumbList.slice();
                newThumbs[streamIndex].counter = action.counter;

                const newState = {
                    ...state,
                    list: newThumbs
                };

                return newState;
            }

            return state;
        }
        case 'selectStream': {
            const prevMainStream = state.current;
            const currentStream = { ...prevMainStream } as LiveStream;
            const currentThumbs = state.list.slice();

            action.informUserView(currentStream.hash, false);

            const newThumbs = currentThumbs.filter((value) => {
                return value.hash !== action.stream.hash;
            });
            newThumbs.push(currentStream);
            newThumbs.sort((a, b) => {
                return moment.utc(b.created_at).diff(moment.utc(a.created_at))
            });

            action.informUserView(action.stream.hash, true);

            const newState = {
                current: action.stream,
                list: newThumbs
            };
            return newState;
        }
        default:
            throw new Error(` -- Stream Reducer -- Invalid action type: ${action}`)
    }
}