import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import { Event } from 'model/model';
import { getNextEvents } from 'api/event';
import LiveBadge from 'components/common/livebadge/LiveBadge';
import Loading from 'components/common/loading/Loading';

import backgroundImg from 'assets/images/splash-background.jpg';
import homeButtonIcon from 'assets/icons/play-icon.svg';

interface IHomeProps { }

function Home(props: IHomeProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [currentEvent, setCurrentEvent] = useState(null as Event | null);
    const [nextEvents, setNextEvents] = useState([] as Event[]);
    const [isOngoing, setIsOngoing] = useState(false);

    useEffect(() => {
        getEventsInformation();
    }, []);

    const getEventsInformation = async () => {
        let result;
        try {
            result = await getNextEvents();
        } catch (err) {
            console.error('  -- Error retrieving ongoing event information');
            return;
        }

        setCurrentEvent(result.eventInfo);
        setNextEvents(result.nextEvents);
        setIsOngoing(result.ongoingEvent);
        setIsLoading(false);
    }

    let eventName = '';
    if (currentEvent) {
        console.log(currentEvent)
        eventName = currentEvent.hometeamname + ' vs ' + currentEvent.awayteamname;
    }

    if (isLoading) { return <Loading />; }

    return (
        <section className="home-section">
            <div className="splash-container">
                <div className="splash-backdrop" />
                <div className="splash-background" style={{ backgroundImage: `url("${backgroundImg}")` }} />

                <div className="event-description">
                    {isOngoing ? <LiveBadge /> : <></>}

                    {isOngoing ?
                        <Typography className="current-event-name" variant="h1">
                            {eventName}
                        </Typography> :
                        <Typography className="no-events">
                            No ongoing events yet... Please wait
                        </Typography>
                    }

                    <Button
                        className="home-main-btn"
                        variant="contained"
                        color="primary"
                        startIcon={<img src={homeButtonIcon} alt="See now icon" />}
                        component={NavLink}
                        to="/live-game"
                    >
                        {isOngoing ? 'See now' : 'Wait for transmission'}
                    </Button>
                </div>
            </div>

            <div className="events-container">
                <Typography
                    className="events-list-title"
                    variant="h3"
                >
                    Future Events
                </Typography>

                <div className="events-list">
                    {nextEvents.map((ev, index) => (
                        <div className="future-event-container" key={index}>
                            <div className="left-side">
                                <div className="home-team">
                                    <img src={ev.hometeamlogo} alt="" />
                                    <p>{ev.hometeamname}</p>
                                </div>
                                <div className="away-team">
                                    <img src={ev.awayteamlogo} alt="" />
                                    <p>{ev.awayteamname}</p>
                                </div>
                            </div>
                            <div className="right-side">
                                <h4>{ev.leaguename}</h4>
                                <p>{moment(ev.startdate).format("dddd, MMMM DD, HH[h]mm")}</p>
                            </div>
                        </div>
                    ))}
                    <Typography color="secondary" className="no-future-events">
                        {nextEvents.length === 0 ? "No future events..." : <></>}
                    </Typography>
                </div>
            </div>
        </section>

    );
}

export default Home;