import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { HTTPMethod } from 'model/enum';
import settings from './config.js';

const api = axios.create();

/* Request interceptor for API calls */
// add the auth token header to requests
api.interceptors.request.use(
    (config: any) => {
        const accessToken = localStorage.getItem('accessToken');
        // const { origin } = new URL(config.url !== undefined ? config.url : '');
        // const allowedOrigins = [baseUrl];

        if (config.headers && accessToken) {
            config.headers.authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

class API {
    private protocol: string;
    private ip: string;
    private port: number;
    private path: string;
    public base: string;
    constructor(protocol: string | undefined, ip: string | undefined, port: string | undefined, path: string | undefined) {
        this.protocol = protocol ?? 'http';
        this.ip = ip ?? 'localhost';
        this.port = parseInt(port ?? '80');
        this.path = path ?? "";

        this.base = `${this.protocol}://${this.ip}:${this.port}${this.path}/api`;
    }
}

export const fetchRequest = (
    method: HTTPMethod,
    path: string,
    body: Object = {},
    cors: boolean = true,
    headers: any = { 'Content-Type': 'application/json' }
): Promise<any> => {
    let options: AxiosRequestConfig = {};

    if (cors) {
        options.withCredentials = true;
    }

    if (method !== HTTPMethod.GET) {
        options.headers = headers;
        options.data = body;
        options.method = method;
    }

    console.log('[api] fetchRequest path', path);

    return new Promise((res: Function, rej: Function) => {
        api(path, options)
            .then((resp: any) => res(resp.data))
            .catch((error: AxiosError) => {
                rej(error.response);
            });
    });
};

export const apiInfo = new API(
    settings.API_HTTP,
    settings.API_IP,
    settings.API_PORT,
    settings.API_PATH
);
