import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Socket } from 'socket.io-client';

import ChatInput from 'components/livechat/chatinput/ChatInput';
import ChatMessage from 'components/livechat/chatmessage/ChatMessage';
import Loading from 'components/common/loading/Loading';
import { AlwaysScrollToBottom } from 'components/common/scrollbottom/ScrollBottom';
import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';
import { Message } from 'model/model';
import { RootState } from 'redux/reducer';
import { getMessages } from 'api/messages';
import { to } from 'utils/utils';

import PartyPopper from 'assets/icons/party.png';

interface ILiveChat {
    socket: Socket<ServerToClientEvents, ClientToServerEvents>
}

function LiveChat(props: ILiveChat) {
    const { socket } = props;
    const user = useSelector((state: RootState) => state.user);

    const [messages, setMessages] = useState([] as Message[]);
    const [loading, setLoading] = useState(true);

    // Has run initial config
    const hasFetchedData = useRef(false);


    const getAllMessages = async () => {
        const [err, result] = await to(getMessages());

        if (err || !result) {
            console.error(' -- Error retrieving all messages:', err);
            return;
        }

        setMessages(result);
        setLoading(false);
    }

    useEffect(() => {
        if (hasFetchedData.current) { return; }
        hasFetchedData.current = true;

        socket.on('newMessage', (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
        });

        getAllMessages();
    });

    const RenderMessages = () => {
        if (loading) {
            return <Loading />;
        }

        if (messages.length === 0) {
            return (
                <div className="empty-chat-container">
                    <div className="empty-chat-title">
                        <Typography component="p">
                            Welcome to the live chat
                        </Typography>
                        <img src={PartyPopper} alt="Party Popper" />
                    </div>
                    <Typography className="empty-chat-txt" component="p">
                        Send the first message!
                    </Typography>
                </div>
            );
        } else {
            return messages.map((message, index) =>
                <ChatMessage
                    key={index}
                    username={message.username}
                    content={message.content}
                    ownmessage={message.username === user.username}
                />
            );
        }
    }

    return (
        <div className="live-chat-container">
            <div className="live-chat-absolute">
                <div className="chat-messages-container">
                    <div id="live-chat" className="chat-messages">
                        {RenderMessages()}
                        <AlwaysScrollToBottom />
                    </div>
                </div>

                <ChatInput socket={socket} />

            </div>
        </div>
    );
}

export default LiveChat;