import React from "react";

export default function Loading() {
    const loadingClasses = "loading";

    return (
      <div className={loadingClasses}>
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
      </div>
    );
}