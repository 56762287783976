import React from 'react';
import { Socket } from 'socket.io-client';
import { Typography } from '@mui/material';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { LiveStream } from 'model/model';
import { ClientToServerEvents, ServerToClientEvents } from 'model/sockets';
import StreamThumb from 'components/sidebar/streamthumb/StreamThumb';

interface IFavoriteVideos {
    thumbList: LiveStream[];
    socket: Socket<ServerToClientEvents, ClientToServerEvents>
    selectStream: (stream: LiveStream) => void;
    dispatchLiveStreams: (action: any) => void;
}

function FavoriteVideos(props: IFavoriteVideos) {
    const { thumbList, socket, selectStream, dispatchLiveStreams } = props;
    const streamIndex = thumbList.findIndex(thumb => thumb.favorite === true);

    if (streamIndex === -1) {
        return (
            <Typography color="secondary" className="no-streams">
                No favorite streams yet...
            </Typography>
        );
    }

    return (
        <TransitionGroup className="stream-list">
            {thumbList.map((stream: LiveStream, index: number) =>
                <CSSTransition
                    key={stream.hash}
                    classNames="thumbanim"
                    timeout={200}
                >
                    {stream.favorite ? 
                        (<StreamThumb 
                            key={stream.hash} stream={stream} socket={socket}
                            selectStream={selectStream} dispatchLiveStreams={dispatchLiveStreams} />)
                        : <></>}
                </CSSTransition>
            )}
        </TransitionGroup>
    );
}

export default FavoriteVideos;