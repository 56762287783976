import React from 'react';
import { Link, Typography } from '@mui/material';

function Footer(props: {}) {
    return (
        <div className="footer-container">
            <div className="footer-content">
                <Typography
                    component="p"
                    color="secondary"
                >
                    &copy; {new Date().getFullYear()} PlayOff Portugal, Inc. All rights reserved.
                </Typography>
                <div className="footer-fill" />

                {/*<Typography
                    className="footer-link"
                    color="secondary"
                    component="div"
                >
                    <Link
                        href="#"
                        variant="inherit"
                        color="inherit"
                        underline="none"
                    >
                        Legal Information
                    </Link>

                    <Link
                        href="#"
                        variant="inherit"
                        color="inherit"
                        underline="none"
                    >
                        Terms of Service
                    </Link>

                    <Link
                        href="#"
                        variant="inherit"
                        color="inherit"
                        underline="none"
                    >
                        Privacy Policy
                    </Link>
                </Typography>*/}
            </div>
        </div>
    );
}

export default Footer;